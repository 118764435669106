import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import useCart from "hooks/useCart";
import { formatCurrencyWithSymbol } from "utils/formats/number";

import * as S from "./styles";

const FooterCart = () => {
  const [cartNumber, setCartNumber] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const { toggleOpen, cart } = useCart();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const listenToScroll = () => {
    const rootWrapper = document.querySelector(".mainwrapper");
    if (rootWrapper === null) return;
    
    const heightToShowFrom = 100;
    const winScroll = rootWrapper?.scrollTop;

    setIsVisible(winScroll > heightToShowFrom);
  };

  useEffect(() => {
    setCartNumber(cart.items.length);
  }, [cart]);

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener("scroll", listenToScroll, true);
    } else {
      window.removeEventListener("scroll", listenToScroll);
    }

    return () => window.removeEventListener("scroll", listenToScroll);
  }, [isMobile]);

  return (
    <S.Wrapper onClick={toggleOpen} isVisible={isMobile || isVisible}>
      <S.CartIcon role="button" onClick={toggleOpen}>
        {cartNumber > 0 && (
          <S.CartNumber className="cart-number cart-bounce">
            {cartNumber}
          </S.CartNumber>
        )}

        <img
          width={32}
          height={32}
          src="/img/icons-performance-cart-white.svg"
        />
      </S.CartIcon>

      <S.CompleteText>Checkout</S.CompleteText>

      <S.TotalCart>{formatCurrencyWithSymbol(cart.total / 100)}</S.TotalCart>
    </S.Wrapper>
  );
};

export default FooterCart;
