import Case from "case";
import {
  differenceInHours,
  differenceInMinutes,
  getDay,
  getHours,
  getMinutes,
} from "date-fns";

import { MerchantDetailsProp } from "../../Explore/interface";

import * as S from "./styles";

export interface MerchantAvailabilityProps {
  merchantDetails: MerchantDetailsProp;
}

const servicesMap: { [key: string]: string } = {
  takeout: "Pick-up",
  voucher: "Online Purchase",
};

const getMerchantsOpenStatus = (merchantDetails: MerchantDetailsProp) => {
  if (!merchantDetails.isOpen) return "unavailable";
  return (
    !merchantDetails.isOnShift || merchantDetails.servicesAvailable === null
  );
};

const getServices = (status: string[] | undefined) => {
  const mappedStatus = status?.map(
    (item: string) => servicesMap[item] || Case.capital(Case.lower(item))
  );
  const joinedStatus = mappedStatus?.join(" • ");
  return joinedStatus;
};

const defineNextShiftAmPm = (time: string) => {
  const [hour, minute] = time.split(":");
  const isAM = parseInt(hour) <= 12;
  const sufix = isAM ? "AM " : "PM";
  const firstHour = isAM ? parseInt(hour) : parseInt(hour) - 12;
  const firstHourWithPrefix = firstHour >= 9 ? firstHour : "0" + firstHour;
  const formattedMinute = parseInt(minute) < 10 ? `0${minute}` : minute;

  return `${firstHourWithPrefix}:${formattedMinute} ${sufix}`;
};

const getNextShift = (
  isOnShift: boolean,
  nextShift: string,
  backIn: boolean
) => {
  if (isOnShift) {
    return "";
  }
  if (!nextShift) {
    return "Closed";
  }

  const nextShiftDate = new Date(nextShift);
  const today = new Date();
  const ONE_HOUR = 60;
  const TWO_HOURS = 120;
  const hoursDiff = differenceInHours(nextShiftDate, today);
  const prefix = backIn ? "Back" : "Opens";

  const minsDiff = differenceInMinutes(nextShiftDate, today);
  if (minsDiff < 45) {
    return `${prefix} in ${minsDiff} minutes`;
  }

  const isOneHourDifference = minsDiff <= ONE_HOUR;
  if (isOneHourDifference) {
    return `${prefix} in 1 hour`;
  }

  const isTwoHoursDifference = minsDiff <= TWO_HOURS;
  if (isTwoHoursDifference) {
    return `${prefix} in ${hoursDiff} hours`;
  }

  const minDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const hours = getHours(nextShiftDate);
  const minutes = getMinutes(nextShiftDate);
  const minDay = minDays[getDay(nextShiftDate)];
  const amPmHour = defineNextShiftAmPm(`${hours}:${minutes}`);

  return `${prefix} ${minDay} ${amPmHour}`;
};

const getAvailableAgainAt = (merchantDetails: MerchantDetailsProp) => {
  if (merchantDetails.isOpen) return false;

  if (
    merchantDetails.availableAgainAt === null ||
    merchantDetails.availableAgainAt === undefined
  ) {
    return false;
  }

  const today = new Date();
  const TWO_DAYS = 48;
  const availableAgainAtDate = new Date(merchantDetails.availableAgainAt);
  const hoursDiff = differenceInHours(availableAgainAtDate, today);

  console.log(hoursDiff);
  if (hoursDiff > TWO_DAYS) return false;

  return getNextShift(false, merchantDetails.availableAgainAt, true);
};

const MerchantAvailability = ({
  merchantDetails,
}: MerchantAvailabilityProps) => {
  const isClosed = getMerchantsOpenStatus(merchantDetails);
  const availableAgainAtStatus = getAvailableAgainAt(merchantDetails);

  if (availableAgainAtStatus && !merchantDetails.isOpen)
    return <S.ItemStatus> {availableAgainAtStatus} </S.ItemStatus>;

  return (
    <S.ItemStatus>
      {!isClosed && getServices(merchantDetails?.servicesAvailable)}
      {isClosed &&
        isClosed !== "unavailable" &&
        getNextShift(
          merchantDetails.isOnShift,
          merchantDetails.nextShift,
          false
        )}
      {isClosed === "unavailable" && "Temporarily Unavailable"}
    </S.ItemStatus>
  );
};

export default MerchantAvailability;
